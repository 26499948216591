import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filtrofechalogin',
})
export class FiltrofechaloginPipe implements PipeTransform {
  transform(row: any, f1: Date, f2?: Date): any {
    f1.toString().length == 0
      ? (f1 = new Date('1995-12-25T11:30:00.000Z'))
      : f1;
    f2 == null ? (f2 = new Date()) : f2;
    if (f1 >= f2 || f1 == null) {
      return row;
    }
    f1 = new Date(f1);
    f1.setHours(0, 0, 0, 0);
    f2 = new Date(f2);
    f2.setHours(23, 59, 59, 999);
    console.log(f1, f2);
    row = row.filter(x => {
      return new Date(x) >= f1 && new Date(x) <= f2;
    });

    return row.sort((a, b) => a < b);
  }
}
