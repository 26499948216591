import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filtroEstudiantesPipe',
  pure: true,
})
export class FiltroEstudiantesPipe implements PipeTransform {
  transform(
    estudiantesArray: any[],
    gradosArray: any[],
    docente: string,
    grado: string,
    salon: string
  ): any[] {
    return estudiantesArray.filter(estudiante => {
      let conditionGrado: boolean = false;
      let conditionSalon: boolean = false; // estudiante.filterInfo.salones[0] === salon;
      let conditionDocente: boolean = false;

      if (grado === 'Todos') {
        conditionGrado = true;
      } else if (
        estudiante.filterInfo.grados &&
        estudiante.filterInfo.grados.length > 0
      ) {
        conditionGrado =
          this.getGradoNombreFromCodigo(
            gradosArray,
            estudiante.filterInfo.grados[0]
          ) === grado;
      }

      if (salon === 'Todos') {
        conditionSalon = true;
      } else if (
        estudiante.filterInfo.salones &&
        estudiante.filterInfo.salones.length > 0
      ) {
        conditionSalon =
          estudiante.filterInfo.salones[0].attributes.nombre === salon;
      }

      if (docente === 'Todos') {
        conditionDocente = true;
      } else {
        const docentesInEstudiante = this.getDocentesFromEstudiante(estudiante);
        if (docentesInEstudiante.length > 0) {
          conditionDocente = docentesInEstudiante.includes(docente);
        }
      }

      return conditionGrado && conditionSalon && conditionDocente;
    });
  }

  private getDocentesFromEstudiante(estudiante: any) {
    const docentes: string[] = [];
    if (
      estudiante.filterInfo.docentes &&
      estudiante.filterInfo.docentes.length > 0
    ) {
      for (let docente of estudiante.filterInfo.docentes) {
        docentes.push(
          docente.attributes.nombres + ' ' + docente.attributes.apellidos
        );
      }
    }
    return docentes;
  }

  private getGradoNombreFromCodigo(gradosArray, gradoCodigo) {
    const foundGrado = gradosArray.find(grado => grado.codigo === gradoCodigo);
    return foundGrado ? foundGrado.nombre : '';
  }
}
