import { ActivatedRouteSnapshot, Resolve, Router } from '@angular/router';
import { AwsCognitoService } from './aws-cognito-oauth.service';
import { Injectable } from '@angular/core';
import { UserService } from './user.service';

@Injectable({
  providedIn: 'root',
})
export class TokenResolverService implements Resolve<any> {
  constructor(
    private awsCognitoService: AwsCognitoService,
    private router: Router,
    private user: UserService
  ) {}

  resolve(route: ActivatedRouteSnapshot) {
    const urlParams: URLSearchParams = new URLSearchParams(
      window.location.search
    );
    const code: string = urlParams.get('code');

    if (!code) {
      console.error('Cógnito code is null');
      return null;
    }
    this.getTokenDetailsFromCognito(code);
  }

  getTokenDetailsFromCognito(code: string) {
    this.user.logout();
    return this.awsCognitoService
      .getTokenDetailsFromCognito(code)
      .then(r => {
        if (r) {
          this.user.setToken(r);
          this.user.start();
          this.router.navigate(['/']);
          return true;
        }
      })
      .catch(e => {
        console.error(e);
        return false;
      });
  }
}
