import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { CallbackComponent } from './components/callback/callback.component';
import { TokenResolverService } from './services/token-resolver.service';

const routes: Routes = [
  {
    path: '',
    loadChildren: () =>
      import('./pages/menu/menu.module').then(m => m.MenuPageModule),
  },
  {
    path: 'callback',
    component: CallbackComponent,
    resolve: {
      access: TokenResolverService,
    },
  },
  {
    path: 'asignar-docente',
    loadChildren: () =>
      import('./pages/institucion/asignar-docente/asignar-docente.module').then(
        m => m.AsignarDocentePageModule
      ),
  },
  {
    path: 'asignar-docente-colaborador',
    loadChildren: () =>
      import(
        './pages/institucion/asignar-docente-colaborador/asignar-docente-colaborador.module'
      ).then(m => m.AsignarDocenteColaboradorPageModule),
  },
  {
    path: 'asignar-familiar',
    loadChildren: () =>
      import(
        './pages/institucion/asignar-familiar/asignar-familiar.module'
      ).then(m => m.AsignarFamiliarPageModule),
  },
  {
    path: 'detalle-usuario',
    loadChildren: () =>
      import('./pages/institucion/detalle-usuario/detalle-usuario.module').then(
        m => m.DetalleUsuarioPageModule
      ),
  },
  {
    path: 'editar-estudiante',
    loadChildren: () =>
      import(
        './pages/institucion/editar-estudiante/editar-estudiante.module'
      ).then(m => m.EditarEstudiantePageModule),
  },
  {
    path: 'masinfousuariologin',
    loadChildren: () =>
      import('./pages/masinfousuariologin/masinfousuariologin.module').then(
        m => m.MasinfousuariologinPageModule
      ),
  },
  {
    path: 'registrar-estudiante',
    loadChildren: () =>
      import(
        './pages/institucion/registrar-estudiante/registrar-estudiante.module'
      ).then(m => m.RegistrarEstudiantePageModule),
  },
  {
    path: 'registrar-usuario',
    loadChildren: () =>
      import(
        './pages/institucion/registrar-usuario/registrar-usuario.module'
      ).then(m => m.RegistrarUsuarioPageModule),
  },
  {
    path: 'reset-contrasena-usuario',
    loadChildren: () =>
      import(
        './pages/institucion/reset-contrasena-usuario/reset-contrasena-usuario.module'
      ).then(m => m.ResetContrasenaUsuarioPageModule),
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
