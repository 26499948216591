import { Config } from '@ionic/angular';
import { Injectable } from '@angular/core';

declare var AWS: any;
declare var AWSCognito: any;

declare const aws_cognito_region;
declare const aws_cognito_identity_pool_id;
declare const aws_user_pools_id;
declare const aws_user_pools_web_client_id;

@Injectable({
  providedIn: 'root',
})
export class Cognito {
  constructor(public config: Config) {
    AWSCognito.config.region = aws_cognito_region;
    AWSCognito.config.credentials = new AWS.CognitoIdentityCredentials({
      IdentityPoolId: aws_cognito_identity_pool_id,
    });
    AWSCognito.config.update({ customUserAgent: AWS.config.customUserAgent });
  }
}
