import { FiltrofechaloginPipe } from './filtrofechalogin.pipe';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FiltrosearchPipe } from './filtrosearch.pipe';
import { FiltroEstudiantesPipe } from './filtroEstudiantes.pipe';

@NgModule({
  declarations: [FiltrofechaloginPipe, FiltrosearchPipe, FiltroEstudiantesPipe],
  exports: [FiltrofechaloginPipe, FiltrosearchPipe, FiltroEstudiantesPipe],
  imports: [CommonModule],
})
export class PipesModule {}
