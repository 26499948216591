import { ApiCommonService } from './services/api/api-common.service';
import { AppComponent } from './app.component';
import { ApiEnrolamientoService } from './services/api/enrolamiento.service';
import { AppRoutingModule } from './app-routing.module';
import { BrowserModule } from '@angular/platform-browser';
import { CacheModule } from 'ionic-cache';
import { HttpClientModule } from '@angular/common/http';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { NgModule } from '@angular/core';
import { RouteReuseStrategy } from '@angular/router';
import { TokenResolverService } from './services/token-resolver.service';
import { UserService } from './services/user.service';
import { Cognito } from './services/aws-cognito.service';
import { PipesModule } from './pipes/pipes.module';

@NgModule({
  declarations: [AppComponent],
  imports: [
    AppRoutingModule,
    BrowserModule,
    CacheModule.forRoot({ keyPrefix: 'skalo' }),
    HttpClientModule,
    IonicModule.forRoot(),
    PipesModule,
  ],
  providers: [
    ApiCommonService,
    ApiEnrolamientoService,
    Cognito,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    TokenResolverService,
    UserService,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
