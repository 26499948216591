import { ExpertoData } from './../../../../skaloadmin/src/app/models/experto';
import { Experto } from './../models/experto';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filtrosearch',
})
export class FiltrosearchPipe implements PipeTransform {
  transform(arreglo: any[], texto: any, columna: string[]): any[] {
    if (texto === '') {
      return arreglo;
    }
    var result = '';
    texto = texto.toLowerCase();
    console.log(texto);

    return arreglo.filter(item => {
      //  console.log(item)
      for (var i = 0; i < columna.length; i++) {
        if (i === 0) {
          if (item[columna[i]] !== undefined) {
            result = item[columna[i]].toLocaleLowerCase().includes(texto);
          } else if (item['attributes'][columna[i]] !== undefined) {
            result = item['attributes'][columna[i]]
              .toLocaleLowerCase()
              .includes(texto);
          } else {
            result = item.data[columna[i]].toLocaleLowerCase().includes(texto);
          }
        } else {
          if (item[columna[i]]) {
            result =
              result || item[columna[i]].toLocaleLowerCase().includes(texto);
          } else {
            result = result;
          }
        }
      }

      return result;
    });
  }
}
