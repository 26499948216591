import { CacheService } from 'ionic-cache';
import { Component } from '@angular/core';
import { Platform } from '@ionic/angular';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {
  constructor(
    cache: CacheService,
    private platform: Platform
  ) {
    cache.setDefaultTTL(60 * 60);
  }

  initializeApp() {
    this.platform.ready().then(() => {
      //      this.statusBar.styleDefault();
      //      this.splashScreen.hide();
    });
  }
}
