import { AnioEscolar } from 'src/app/models/anioEscolar';
import { ApiCommonService } from './api-common.service';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Institucion } from 'src/app/models/institucion';
import { Periodo } from 'src/app/models/periodo';
import { Salon } from 'src/app/models/salon';
import { UserService } from '../user.service';

@Injectable({
  providedIn: 'root',
})
export class ApiEnrolamientoService {
  constructor(
    private common: ApiCommonService,
    private http: HttpClient,
    private user: UserService
  ) {}

  public modoDebug() {
    return this.common.modoDebug();
  }

  adminSetUserPassword(datos: {
    id: string;
    password: string;
    institucionId: string;
  }) {
    const url: string = this.common.makeDataURL(
      'registro_inst_est',
      'usuario/admin-set-contrasena',
      ''
    );
    const pdata = JSON.stringify(datos);
    const options = {
      headers: {
        Authorization: this.user.getToken(),
      },
    };
    return this.http
      .post(url, pdata, options)
      .toPromise()
      .then(this.common.extractData)
      .catch(this.common.handleError);
  }

  cambioAnio(anioId: string) {
    const url: string = this.common.makeDataURL(
      'registro_inst_est',
      'cambioAnio/',
      ''
    );

    const datos = {
      anioEscolarId: anioId,
    };
    const pdata = JSON.stringify(datos);
    const options = {
      headers: {
        Authorization: this.user.getToken(),
      },
    };
    return this.http
      .post(url, pdata, options)
      .toPromise()
      .then(this.common.extractData)
      .catch(this.common.handleError);
  }

  cambiarContrasenaUsuario(datos) {
    const url: string = this.common.makeDataURL(
      'registro_inst_est',
      'usuario/cambiarcontrasena',
      ''
    );
    const pdata = JSON.stringify(datos);
    return this.http
      .post(url, pdata)
      .toPromise()
      .then(this.common.extractData)
      .catch(this.common.handleError);
  }

  cambioSalon(anioId) {
    const url: string = this.common.makeDataURL(
      'registro_inst_est',
      'cambioSalon/',
      ''
    );

    const datos = {
      anioEscolarId: anioId,
    };
    const pdata = JSON.stringify(datos);
    const options = {
      headers: {
        Authorization: this.user.getToken(),
      },
    };
    return this.http
      .post(url, pdata, options)
      .toPromise()
      .then(this.common.extractData)
      .catch(this.common.handleError);
  }

  deleteAnioEscolar(id) {
    const url: string = this.common.makeDataURL(
      'registro_inst_est',
      'institucion/anio-escolar/',
      id
    );
    const options = {
      headers: {
        Authorization: this.user.getToken(),
      },
    };
    return this.http
      .delete(url, options)
      .toPromise()
      .then(this.common.extractData)
      .catch(this.common.handleError);
  }

  deleteEntidad(entidad, id) {
    const url: string = this.common.makeDataURL(
      'registro_inst_est',
      `entidad/${entidad}`,
      id
    );
    const options = {
      headers: {
        Authorization: this.user.getToken(),
      },
    };
    return this.http
      .delete(url, options)
      .toPromise()
      .then(this.common.extractData)
      .catch(this.common.handleError);
  }

  deletePeriodo(institucionId, periodoId) {
    const url: string = this.common.makeDataURL(
      'registro_inst_est',
      'institucion/periodo/',
      institucionId + '/' + periodoId
    );
    const options = {
      headers: {
        Authorization: this.user.getToken(),
      },
    };
    return this.http
      .delete(url, options)
      .toPromise()
      .then(this.common.extractData)
      .catch(this.common.handleError);
  }

  deleteSalon(institucionId, salonId) {
    const url: string = this.common.makeDataURL(
      'registro_inst_est',
      'institucion/salon/',
      institucionId + '/' + salonId
    );
    const options = {
      headers: {
        Authorization: this.user.getToken(),
      },
    };
    return this.http
      .delete(url, options)
      .toPromise()
      .then(this.common.extractData)
      .catch(this.common.handleError);
  }

  deleteSalonDocente(salonId, docenteId) {
    const url: string = this.common.makeDataURL(
      'registro_inst_est',
      'institucion/salon-docente/',
      salonId + '/' + docenteId
    );
    const options = {
      headers: {
        Authorization: this.user.getToken(),
      },
    };
    return this.http
      .delete(url, options)
      .toPromise()
      .then(this.common.extractData)
      .catch(this.common.handleError);
  }

  deleteSalonImagenSkalo(salonId: any, imagenSkaloId: any) {
    const url: string = this.common.makeDataURL(
      'registro_inst_est',
      'institucion/salon-imagen-skalo/',
      salonId + '/' + imagenSkaloId
    );
    let token = this.user.getToken();
    const options = {
      headers: {
        Authorization: token,
      },
    };
    return this.http
      .delete(url, options)
      .toPromise()
      .then(this.common.extractData)
      .catch(this.common.handleError);
  }

  deleteGradoMateria(institucionId, codigo) {
    const url: string = this.common.makeDataURL(
      'registro_inst_est',
      'institucion/grado-materia/',
      institucionId + '/' + codigo
    );
    const options = {
      headers: {
        Authorization: this.user.getToken(),
      },
    };
    return this.http
      .delete(url, options)
      .toPromise()
      .then(this.common.extractData)
      .catch(this.common.handleError);
  }

  fijarContrasenaObligatoriaUsuario(datos) {
    const url: string = this.common.makeDataURL(
      'registro_inst_est',
      'usuario/fijarcontrasena',
      ''
    );
    const pdata = JSON.stringify(datos);
    // const options = {
    //   headers: {
    //     Authorization: this.user.getToken(),
    //     'Content-Type': 'application/json'
    //   }
    // };
    //console.log(pdata);
    return this.http
      .post(url, pdata)
      .toPromise()
      .then(this.common.extractData)
      .catch(this.common.handleError);
  }

  getAnioEscolar(id: string) {
    const url: string = this.common.makeDataURL(
      'registro_inst_est',
      'institucion/anio-escolar/',
      id
    );
    const options = {
      headers: {
        Authorization: this.user.getToken(),
      },
    };
    return this.http
      .get(url, options)
      .toPromise()
      .then(this.common.extractData)
      .catch(this.common.handleError);
  }

  getCurrentAnioEscolarOfInstitucion(institucionId: string) {
    const url: string = this.common.makeDataURL(
      'registro_inst_est',
      'institucion/current-anio-escolar/',
      institucionId
    );
    const options = {
      headers: {
        Authorization: this.user.getToken(),
      },
    };

    return this.http
      .get(url, options)
      .toPromise()
      .then(this.common.extractData)
      .catch(this.common.handleError);
  }

  getEntidad(entidad, id) {
    const url: string = this.common.makeDataURL(
      'registro_inst_est',
      `entidad/${entidad}`,
      id
    );
    const options = {
      headers: {
        Authorization: this.user.getToken(),
      },
    };
    return this.http
      .get(url, options)
      .toPromise()
      .then(this.common.extractData)
      .catch(this.common.handleError);
  }

  getConsultarImagenSkalo(id: string) {
    const url: string = this.common.makeDataURL(
      'registro_inst_est',
      'imagen-skalo/',
      id
    );
    const options = {
      headers: {
        Authorization: this.user.getToken(),
      },
    };
    return this.http
      .get(url, options)
      .toPromise()
      .then(this.common.extractData)
      .catch(this.common.handleError);
  }

  getEsDocenteFamiliarOAdmin(userId) {
    const url: string = this.common.makeDataURL(
      'registro_inst_est',
      'usuario/esdocentefamiliaroadmin/',
      userId
    );
    const options = {
      headers: {
        Authorization: this.user.getToken(),
      },
    };
    return this.http
      .get(url, options)
      .toPromise()
      .then(this.common.extractData)
      .catch(this.common.handleError);
  }

  getEstudiante(estudianteId) {
    const url: string = this.common.makeDataURL(
      'registro_inst_est',
      'estudiante/',
      estudianteId
    );
    const options = {
      headers: {
        Authorization: this.user.getToken(),
      },
    };
    return this.http
      .get(url, options)
      .toPromise()
      .then(this.common.extractData)
      .catch(this.common.handleError);
  }

  getEstudianteRelaciones(estudianteId) {
    const url: string = this.common.makeDataURL(
      'registro_inst_est',
      'estudiante/relaciones/',
      estudianteId
    );
    const options = {
      headers: {
        Authorization: this.user.getToken(),
      },
    };
    return this.http
      .get(url, options)
      .toPromise()
      .then(this.common.extractData)
      .catch(this.common.handleError);
  }

  getEsUsuarioSkaloAdmin(userId) {
    const url: string = this.common.makeDataURL(
      'registro_inst_est',
      'usuario/esusuarioskaloadmin/',
      userId
    );
    const options = {
      headers: {
        Authorization: this.user.getToken(),
      },
    };
    return this.http
      .get(url, options)
      .toPromise()
      .then(this.common.extractData)
      .catch(this.common.handleError);
  }

  // getListarEstudiantesGrado(gradoId) {
  //   const url: string = this.common.makeDataURL('registro_inst_est', 'estudiantes/grado/', gradoId);
  //   const options = {
  //     headers: {
  //       Authorization: this.user.getToken(),
  //     }
  //   };
  //   return this.http.get(url,options).toPromise()
  //   .then(this.common.extractData)
  //   .catch(this.common.handleError);
  // }

  getListarEstudiantesInstitucion(institucionId, lastKey) {
    if (lastKey != '0') {
      lastKey = JSON.stringify(lastKey);
    }
    const url: string = this.common.makeDataURL(
      'registro_inst_est',
      'estudiantes/institucion/',
      institucionId + '/' + lastKey
    );
    const options = {
      headers: {
        Authorization: this.user.getToken(),
      },
    };
    return this.http
      .get(url, options)
      .toPromise()
      .then(this.common.extractData)
      .catch(this.common.handleError);
  }

  getListarEstudiantesInstitucionExport(institucionId: string, lastKey) {
    if (lastKey != '0') {
      lastKey = JSON.stringify(lastKey);
    }
    const url: string = this.common.makeDataURL(
      'registro_inst_est',
      'estudiantes/institucionExport/',
      institucionId + '/' + lastKey
    );
    const options = {
      headers: {
        Authorization: this.user.getToken(),
      },
    };

    return this.http
      .get(url, options)
      .toPromise()
      .then(this.common.extractData)
      .catch(this.common.handleError);
  }

  getListarFechasDocente(docenteId) {
    const url: string = this.common.makeDataURL(
      'registro_inst_est',
      'fechasdocente/',
      docenteId
    );
    const options = {
      headers: {
        Authorization: this.user.getToken(),
      },
    };
    return this.http
      .get(url, options)
      .toPromise()
      .then(this.common.extractData)
      .catch(this.common.handleError);
  }

  getListarInstituciones(lastKey = '0') {
    if (lastKey != '0') {
      if (typeof lastKey !== 'string') {
        lastKey = JSON.stringify(lastKey);
      }
    }
    const url: string = this.common.makeDataURL(
      'registro_inst_est',
      'instituciones/usuario/',
      lastKey
    );
    const options = {
      headers: {
        Authorization: this.user.getToken(),
      },
    };
    return this.http
      .get(url, options)
      .toPromise()
      .then(this.common.extractData)
      .catch(this.common.handleError);
  }

  getListarAniosEscolaresInstitucion(institucionId) {
    const url: string = this.common.makeDataURL(
      'registro_inst_est',
      'institucion/anios-escolares/',
      institucionId
    );
    const options = {
      headers: {
        Authorization: this.user.getToken(),
      },
    };
    console.log(url);
    console.log(options);
    return this.http
      .get(url, options)
      .toPromise()
      .then(this.common.extractData)
      .catch(this.common.handleError);
  }

  getListarSalonesDocenteInstitucion(institucionId, docenteId) {
    const url: string = this.common.makeDataURL(
      'registro_inst_est',
      'institucion/salones-docente/',
      institucionId + '/' + docenteId
    );
    const options = {
      headers: {
        Authorization: this.user.getToken(),
      },
    };
    return this.http
      .get(url, options)
      .toPromise()
      .then(this.common.extractData)
      .catch(this.common.handleError);
  }

  getListarSalonesDocentesInstitucion(institucionId) {
    const url: string = this.common.makeDataURL(
      'registro_inst_est',
      'institucion/salones-docentes/',
      institucionId
    );
    const options = {
      headers: {
        Authorization: this.user.getToken(),
      },
    };
    return this.http
      .get(url, options)
      .toPromise()
      .then(this.common.extractData)
      .catch(this.common.handleError);
  }

  getListarPeriodosInstitucion(institucionId) {
    const url: string = this.common.makeDataURL(
      'registro_inst_est',
      'institucion/periodos/',
      institucionId
    );
    const options = {
      headers: {
        Authorization: this.user.getToken(),
      },
    };
    return this.http
      .get(url, options)
      .toPromise()
      .then(this.common.extractData)
      .catch(this.common.handleError);
  }

  getListarSalonesInstitucion(institucionId) {
    const url: string = this.common.makeDataURL(
      'registro_inst_est',
      'institucion/salones/',
      institucionId
    );
    const options = {
      headers: {
        Authorization: this.user.getToken(),
      },
    };
    return this.http
      .get(url, options)
      .toPromise()
      .then(this.common.extractData)
      .catch(this.common.handleError);
  }

  getListarGradosMaterias(institucionId) {
    const url: string = this.common.makeDataURL(
      'registro_inst_est',
      'institucion/grados-materias/',
      institucionId
    );
    const options = {
      headers: {
        Authorization: this.user.getToken(),
      },
    };
    return this.http
      .get(url, options)
      .toPromise()
      .then(this.common.extractData)
      .catch(this.common.handleError);
  }

  getListarUsuariosInstitucion(institucionId) {
    const url: string = this.common.makeDataURL(
      'registro_inst_est',
      'usuarios/institucion/',
      institucionId
    );
    const options = {
      headers: {
        Authorization: this.user.getToken(),
      },
    };
    return this.http
      .get(url, options)
      .toPromise()
      .then(this.common.extractData)
      .catch(this.common.handleError);
  }

  getLoginDocente(docenteId) {
    const url: string = this.common.makeDataURL(
      'registro_inst_est',
      'docente/loginregister/',
      docenteId
    );
    const options = {
      headers: {
        Authorization: this.user.getToken(),
      },
    };
    return this.http
      .get(url, options)
      .toPromise()
      .then(this.common.extractData)
      .catch(this.common.handleError);
  }

  getPeriodo(institucionId: Institucion, periodoId: Periodo) {
    const url: string = this.common.makeDataURL(
      'registro_inst_est',
      'institucion/periodo/',
      institucionId + '/' + periodoId
    );
    let token = this.user.getToken();
    const options = {
      headers: {
        Authorization: token,
      },
    };
    return this.http
      .get(url, options)
      .toPromise()
      .then(this.common.extractData)
      .catch(this.common.handleError);
  }

  getObtenerEstudiante(codigo: string) {
    const url: string = this.common.makeDataURL(
      'registro_inst_est',
      'estudianteCodigo/',
      codigo
    );
    const options = {
      headers: {
        Authorization: this.user.getToken(),
      },
    };
    return this.http
      .get(url, options)
      .toPromise()
      .then(this.common.extractData)
      .catch(this.common.handleError);
  }

  getObtenerUsuario(codigo: string) {
    const url: string = this.common.makeDataURL(
      'registro_inst_est',
      'usuarioCodigo/',
      codigo
    );
    let token = this.user.getToken();
    const options = {
      headers: {
        Authorization: token,
      },
    };
    return this.http
      .get(url, options)
      .toPromise()
      .then(this.common.extractData)
      .catch(this.common.handleError);
  }

  getSalon(institucionId: Institucion, salonId: string) {
    const url: string = this.common.makeDataURL(
      'registro_inst_est',
      'institucion/salon/',
      institucionId + '/' + salonId
    );
    let token = this.user.getToken();
    const options = {
      headers: {
        Authorization: token,
      },
    };
    return this.http
      .get(url, options)
      .toPromise()
      .then(this.common.extractData)
      .catch(this.common.handleError);
  }

  getSalonImagenSkalo(salonId: any, imagenSkaloId: any) {
    const url: string = this.common.makeDataURL(
      'registro_inst_est',
      'institucion/salon-imagen-skalo/',
      salonId + '/' + imagenSkaloId
    );
    let token = this.user.getToken();
    const options = {
      headers: {
        Authorization: token,
      },
    };
    return this.http
      .get(url, options)
      .toPromise()
      .then(this.common.extractData)
      .catch(this.common.handleError);
  }

  getTareasDocenteApoyo(docenteId: any, institucionId: any) {
    const url: string = this.common.makeDataURL(
      'registro_inst_est',
      'institucion/tareas-docente-apoyo/',
      docenteId + '/' + institucionId
    );
    let token = this.user.getToken();
    const options = {
      headers: {
        Authorization: token,
      },
    };
    return this.http
      .get(url, options)
      .toPromise()
      .then(this.common.extractData)
      .catch(this.common.handleError);
  }

  getGradoMateria(institucionId: any, codigo: any) {
    const url: string = this.common.makeDataURL(
      'registro_inst_est',
      'institucion/grado-materia/',
      institucionId + '/' + codigo
    );
    let token = this.user.getToken();
    const options = {
      headers: {
        Authorization: token,
      },
    };
    return this.http
      .get(url, options)
      .toPromise()
      .then(this.common.extractData)
      .catch(this.common.handleError);
  }

  getUsuario(id: string) {
    const url: string = this.common.makeDataURL(
      'registro_inst_est',
      'entidad/usuario',
      ''
    );
    let token = this.user.getToken();
    const options = {
      headers: {
        Authorization: token,
      },
    };
    return this.http
      .get(url, options)
      .toPromise()
      .then(this.common.extractData)
      .catch(this.common.handleError);
  }
  getPiarDinamico(idInstitucion: string) {
    const url: string = this.common.makeDataURL(
      'registro_inst_est',
      'institucion/piar-dinamico/',
      idInstitucion
    );
    let token = this.user.getToken();
    const options = {
      headers: {
        Authorization: token,
      },
    };
    return this.http
      .get(url, options)
      .toPromise()
      .then(this.common.extractData)
      .catch(this.common.handleError);
  }

  getUsuarioActual() {
    const url: string = this.common.makeDataURL(
      'registro_inst_est',
      'usuario/actual',
      ''
    );
    let token = this.user.getToken();
    const options = {
      headers: {
        Authorization: token,
      },
    };
    return this.http
      .get(url, options)
      .toPromise()
      .then(this.common.extractData)
      .catch(async () => {
        this.common.handleError;
        if (token) {
          // token vencido
          await this.user.logout();
          window.location.reload();
        }
      });
  }

  /**
   * Registro primer login docente
   * @param iddocente
   */
  registrarLoginDocente(iddocente: any) {
    const url: string = this.common.makeDataURL(
      'registro_inst_est',
      'docente/loginregister',
      ''
    );
    const pdata = JSON.stringify({
      docente: {
        id: iddocente,
      },
    });
    const options = {
      headers: {
        Authorization: this.user.getToken(),
      },
    };

    return this.http
      .post(url, pdata, options)
      .toPromise()
      .then(this.common.extractData)
      .catch(this.common.handleError);
  }

  /**
   * Graba los datos de una institución
   * @method grabarAsignarFamilar_post
   * @formdata {any} formulario de registro de pista
   * @return {Promise}
   */
  grabarAsignarFamiliar(datos: any) {
    const url: string = this.common.makeDataURL(
      'registro_inst_est',
      'estudiante/familiar',
      ''
    );
    const pdata = JSON.stringify(datos);
    const options = {
      headers: {
        Authorization: this.user.getToken(),
      },
    };
    return this.http
      .post(url, pdata, options)
      .toPromise()
      .then(this.common.extractData)
      .catch(this.common.handleError);
  }

  /**
   * Graba los datos de una institución
   * @method grabarAsignarDocente_post
   * @formdata {any} formulario de registro de pista
   * @return {Promise}
   */
  grabarAsignarDocente(datos: any) {
    const url: string = this.common.makeDataURL(
      'registro_inst_est',
      'imagen-skalo/docente',
      ''
    );
    const pdata = JSON.stringify(datos);
    const options = {
      headers: {
        Authorization: this.user.getToken(),
      },
    };
    return this.http
      .post(url, pdata, options)
      .toPromise()
      .then(this.common.extractData)
      .catch(this.common.handleError);
  }

  /**
   * Graba los datos de una institución
   * @method grabarEstudiante_post
   * @formdata {any} formulario de registro de pista
   * @return {Promise}
   */
  grabarEstudiante(datos: any) {
    const url: string = this.common.makeDataURL(
      'registro_inst_est',
      'estudiante',
      ''
    );
    const pdata = JSON.stringify(datos);
    const options = {
      headers: {
        Authorization: this.user.getToken(),
      },
    };
    if (this.common.modoDebug()) console.log(pdata);
    return this.http
      .post(url, pdata, options)
      .toPromise()
      .then(this.common.extractData)
      .catch(this.common.handleError);
  }

  /**
   * Grabar un conjunto de estudiantes importados desde Excel para una institución
   */
  grabarEstudiantesBatch(datos: any) {
    const url: string = this.common.makeDataURL(
      'registro_inst_est',
      'estudiantes/grabar-batch',
      ''
    );
    const pdata = JSON.stringify(datos);
    const options = {
      headers: {
        Authorization: this.user.getToken(),
      },
    };
    return this.http
      .post(url, pdata, options)
      .toPromise()
      .then(this.common.extractData)
      .catch(this.common.handleError);
  }

  /**
   * Graba los datos de una institución
   * @method grabarInstitucion_post
   * @formdata {any} formulario de registro de pista
   * @return {Promise}
   */

  grabarInstitucion(institucionId: any, institucion: Institucion) {
    const url: string = this.common.makeDataURL(
      'registro_inst_est',
      'institucion',
      ''
    );
    if (institucionId == '0') institucionId = '';
    const pdata = JSON.stringify({
      id: institucionId,
      institucion: institucion.attributes,
    });
    const options = {
      headers: {
        Authorization: this.user.getToken(),
        'Content-Type': 'application/json',
      },
    };
    return this.http
      .post(url, pdata, options)
      .toPromise()
      .then(this.common.extractData)
      .catch(this.common.handleError);
  }

  /**
   * Graba los datos de invitación para un nuevo usuario
   * @method grabarInvitacion
   * @data {any} formulario de registro de pista
   * @return {Promise}
   */
  grabarInvitacion(datos: any) {
    const url: string = this.common.makeDataURL(
      'registro_inst_est',
      'usuario/invitar',
      ''
    );
    const pdata = JSON.stringify(datos);
    const options = {
      headers: {
        Authorization: this.user.getToken(),
      },
    };
    return this.http
      .post(url, pdata, options)
      .toPromise()
      .then(this.common.extractData)
      .catch(this.common.handleError);
  }

  grabarAnioEscolar(institucion: Institucion, anioEscolar: AnioEscolar) {
    const url: string = this.common.makeDataURL(
      'registro_inst_est',
      'institucion/anio-escolar/',
      ''
    );
    const fechaInicioDate = new Date(anioEscolar.attributes.fechaInicio);
    const fechaFinDate = new Date(anioEscolar.attributes.fechaFin);
    // console.log(anioEscolar);
    if (fechaFinDate > fechaInicioDate) {
      const datos = {
        id: anioEscolar.id,
        institucionId: institucion.id,
        nombre: anioEscolar.attributes.nombre,
        fechaInicio: anioEscolar.attributes.fechaInicio,
        fechaFin: anioEscolar.attributes.fechaFin,
        locales: anioEscolar.attributes.locales
          ? anioEscolar.attributes.locales
          : [{ locale: 'en', nombre: anioEscolar.attributes.nombreEn }],
      };
      const pdata = JSON.stringify(datos);
      const options = {
        headers: {
          Authorization: this.user.getToken(),
        },
      };
      return this.http
        .post(url, pdata, options)
        .toPromise()
        .then(this.common.extractData)
        .catch(this.common.handleError);
    } else {
      return Promise.reject(err => {
        console.error('Fecha de inicio debe ser previa a la fecha de fin');
      });
    }
  }

  grabarPeriodo(institucion: Institucion, periodo: Periodo) {
    const url: string = this.common.makeDataURL(
      'registro_inst_est',
      'institucion/periodo/',
      ''
    );
    const fechaInicioDate = new Date(periodo.attributes.fechaInicio);
    const fechaFinDate = new Date(periodo.attributes.fechaFin);
    if (fechaFinDate > fechaInicioDate) {
      const datos = {
        institucion: {
          id: institucion.id, // id: periodo.attributes.institucionId
        },
        periodo: {
          id: periodo.id,
          nombre: periodo.attributes.nombre,
          fechaInicio: periodo.attributes.fechaInicio,
          fechaFin: periodo.attributes.fechaFin,
          anioEscolarId: periodo.attributes.anioEscolarId,
          institucionId: institucion.id,
          locales: periodo.attributes.locales
            ? periodo.attributes.locales
            : [{ locale: 'en', nombre: periodo.attributes.nombreEn }],
        },
      };
      const pdata = JSON.stringify(datos);
      const options = {
        headers: {
          Authorization: this.user.getToken(),
        },
      };
      return this.http
        .post(url, pdata, options)
        .toPromise()
        .then(this.common.extractData)
        .catch(this.common.handleError);
    } else {
      return Promise.reject(err => {
        console.error('Fecha de inicio debe ser previa a la fecha de fin');
      });
    }
  }

  grabarSalon(institucion: Institucion, salon: Salon) {
    const url: string = this.common.makeDataURL(
      'registro_inst_est',
      'institucion/salon/',
      ''
    );

    const datos = {
      institucion: {
        id: institucion.id,
      },
      salon: {
        institucionId: institucion.id,
        id: salon.id,
        nombre: salon.attributes.nombre + '',
        grado: salon.attributes.grado,
        gradoEducativoId: salon.attributes.gradoEducativoId,
        anioEscolarId: salon.attributes.anioEscolarId,
        locales: salon.attributes.locales
          ? salon.attributes.locales
          : [{ locale: 'en', nombre: salon.attributes.nombreEn }],
      },
    };
    const pdata = JSON.stringify(datos);
    const options = {
      headers: {
        Authorization: this.user.getToken(),
      },
    };
    return this.http
      .post(url, pdata, options)
      .toPromise()
      .then(this.common.extractData)
      .catch(this.common.handleError);
  }

  grabarSalones(institucionId, salones: any) {
    const url: string = this.common.makeDataURL(
      'registro_inst_est',
      'institucion/salones/',
      ''
    );

    const datos = {
      institucionId: institucionId,
      salones: salones,
    };
    const pdata = JSON.stringify(datos);
    //console.log(pdata);
    const options = {
      headers: {
        Authorization: this.user.getToken(),
      },
    };
    return this.http
      .post(url, pdata, options)
      .toPromise()
      .then(this.common.extractData)
      .catch(this.common.handleError);
  }

  grabarGradoMateria(datos) {
    const url: string = this.common.makeDataURL(
      'registro_inst_est',
      'institucion/grado-materia/',
      ''
    );
    const pdata = JSON.stringify(datos);
    const options = {
      headers: {
        Authorization: this.user.getToken(),
      },
    };
    return this.http
      .post(url, pdata, options)
      .toPromise()
      .then(this.common.extractData)
      .catch(this.common.handleError);
  }
  grabarSalonDocenteInstitucion(datos: any) {
    const url: string = this.common.makeDataURL(
      'registro_inst_est',
      'institucion/salon-docente',
      ''
    );
    const pdata = JSON.stringify(datos);
    const options = {
      headers: {
        Authorization: this.user.getToken(),
      },
    };
    return this.http
      .post(url, pdata, options)
      .toPromise()
      .then(this.common.extractData)
      .catch(this.common.handleError);
  }

  grabarSalonImagenSkalo(salon: any, imagenSkaloId: any) {
    const url: string = this.common.makeDataURL(
      'registro_inst_est',
      'institucion/salon-imagen-skalo',
      ''
    );
    const datos = {
      salonId: salon.id,
      anioEscolarId: salon.attributes.anioEscolarId,
      imagenSkaloId: imagenSkaloId,
    };

    const pdata = JSON.stringify(datos);
    const options = {
      headers: {
        Authorization: this.user.getToken(),
      },
    };
    return this.http
      .post(url, pdata, options)
      .toPromise()
      .then(this.common.extractData)
      .catch(this.common.handleError);
  }

  /**
   * Grabar un conjunto de estudiantes importados desde Excel para una institución
   */
  grabarRelacionesBatch(datos: any) {
    const url: string = this.common.makeDataURL(
      'registro_inst_est',
      'estudiantes/grabar-relaciones-batch',
      ''
    );
    const pdata = JSON.stringify(datos);
    const options = {
      headers: {
        Authorization: this.user.getToken(),
      },
    };
    return this.http
      .post(url, pdata, options)
      .toPromise()
      .then(this.common.extractData)
      .catch(this.common.handleError);
  }

  /**
   * Graba los datos de una institución
   * @method grabarUsuario_post
   * @formdata {any} formulario de registro de pista
   * @return {Promise}
   */
  grabarUsuario(datos: any) {
    const url: string = this.common.makeDataURL(
      'registro_inst_est',
      'usuario/cambiadatosusuario',
      ''
    );
    const pdata = JSON.stringify(datos);
    const options = {
      headers: {
        Authorization: this.user.getToken(),
      },
    };
    return this.http
      .post(url, pdata, options)
      .toPromise()
      .then(this.common.extractData)
      .catch(this.common.handleError);
  }

  /**
   * Grabar un conjunto de usuarios importados desde Excel para una institución
   */
  grabarUsuariosBatch(datos: any) {
    const url: string = this.common.makeDataURL(
      'registro_inst_est',
      'usuario/grabar-batch',
      ''
    );
    const pdata = JSON.stringify(datos);
    const options = {
      headers: {
        Authorization: this.user.getToken(),
      },
    };
    return this.http
      .post(url, pdata, options)
      .toPromise()
      .then(this.common.extractData)
      .catch(this.common.handleError);
  }

  /**
   * Login usando Cognito API
   */
  loginUsuario(datos: any) {
    const url: string = this.common.makeDataURL(
      'registro_inst_est',
      'usuario/login',
      ''
    );
    const pdata = JSON.stringify(datos);
    return this.http
      .post(url, pdata)
      .toPromise()
      .then(this.common.extractData)
      .catch(this.common.handleError);
  }

  /** }
   * crea o actualiza una institución
   */
  postInstitucion(institucion: Institucion) {
    const url: string = this.common.makeDataURL(
      'registro_inst_est',
      'institucion',
      ''
    );
    const pdata = JSON.stringify({
      institucion: institucion.attributes,
      id: institucion.id,
    });
    const options = {
      headers: {
        Authorization: this.user.getToken(),
      },
    };

    return this.http
      .post(url, pdata, options)
      .toPromise()
      .then(this.common.extractData)
      .catch(this.common.handleError);
  }

  postPiarDinamico(idInstitucion: string, data: any) {
    const url: string = this.common.makeDataURL(
      'registro_inst_est',
      'institucion/piar-dinamico/',
      idInstitucion
    );
    const pdata = JSON.stringify({
      piar: data,
    });
    const options = {
      headers: {
        Authorization: this.user.getToken(),
      },
    };
    return this.http
      .post(url, pdata, options)
      .toPromise()
      .then(this.common.extractData)
      .catch(this.common.handleError);
  }

  /**
   * Recuperar contraseña usando Cognito API
   */
  recuperarContrasena(email: string) {
    const url: string = this.common.makeDataURL(
      'registro_inst_est',
      'usuario/recuperarcontrasena',
      ''
    );
    const pdata = JSON.stringify({
      email: email,
    });
    //console.log(pdata);

    return this.http
      .post(url, pdata)
      .toPromise()
      .then(this.common.extractData)
      .catch(this.common.handleError);
  }

  /**
   * Reset contraseña usando Cognito API
   */
  resetContrasenaUsuario(datos: any) {
    const url: string = this.common.makeDataURL(
      'registro_inst_est',
      'usuario/resetcontrasena',
      ''
    );
    const pdata = JSON.stringify(datos);
    return this.http
      .post(url, pdata)
      .toPromise()
      .then(this.common.extractData)
      .catch(this.common.handleError);
  }

  updateEstudiante(datos: any) {
    const url: string = this.common.makeDataURL(
      'registro_inst_est',
      'estudiante/actualizar-cambio-salon',
      ''
    );
    const pdata = JSON.stringify({ data: datos });
    const options = {
      headers: {
        Authorization: this.user.getToken(),
      },
    };
    return this.http
      .post(url, pdata, options)
      .toPromise()
      .then(this.common.extractData)
      .catch(this.common.handleError);
  }

  updateEstudianteAttributes(estudiante: any) {
    // este servicio no actualiza la imagen-skalo
    const url: string = this.common.makeDataURL(
      'registro_inst_est',
      'estudiante/actualizar-attributes/',
      estudiante.id
    );
    const pdata = JSON.stringify({
      data: { estudiante: estudiante.attributes },
    });
    const options = {
      headers: {
        Authorization: this.user.getToken(),
      },
    };
    return this.http
      .post(url, pdata, options)
      .toPromise()
      .then(this.common.extractData)
      .catch(this.common.handleError);
  }
}
