import { CacheService } from 'ionic-cache';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class ApiCommonService {
  /*
  public EndPointsDev = [
    {type: 'datos_basicos', url: 'https://amzwhlhpzc.execute-api.us-east-2.amazonaws.com/dev-gp/'},
    {type: 'registro_inst_est', url: 'https://yxjmr8i0a1.execute-api.us-east-2.amazonaws.com/dev-gp/'},
    {type: 'ajustes', url: 'https://n266e5a235.execute-api.us-east-2.amazonaws.com/dev-gp/'},
    {type: 'evaluacion', url: 'https://rls758d8fa.execute-api.us-east-2.amazonaws.com/dev-gp/'},
    {type: 'perfil', url: 'https://n266e5a235.execute-api.us-east-2.amazonaws.com/dev-gp/'},
    {type: 'experto', url: 'https://gikb9k9ja5.execute-api.us-east-2.amazonaws.com/dev-gp/'}
  ];
  */

  public EndPointsDev = [
    {
      type: 'datos_basicos',
      url: 'https://lfqjo52dy0.execute-api.us-east-2.amazonaws.com/dev/',
    },
    {
      type: 'registro_inst_est',
      url: 'https://u1gn4spebj.execute-api.us-east-2.amazonaws.com/dev/',
    },
    {
      type: 'ajustes',
      url: 'https://xdder0cue8.execute-api.us-east-2.amazonaws.com/dev/',
    },
    {
      type: 'evaluacion',
      url: 'https://5r1uq3ynye.execute-api.us-east-2.amazonaws.com/dev/',
    },
    {
      type: 'perfil',
      url: 'https://xdder0cue8.execute-api.us-east-2.amazonaws.com/dev/',
    },
    {
      type: 'experto',
      url: 'https://0n18qjv4d7.execute-api.us-east-2.amazonaws.com/dev/',
    },
    { type: 'estadisticas', url: 'https://estadisticas.skalo.co/' },
  ];

  public EndPointsDemo = [
    {
      type: 'datos_basicos',
      url: 'https://jbp0e2djfe.execute-api.us-east-2.amazonaws.com/demo/',
    },
    {
      type: 'registro_inst_est',
      url: 'https://qv3rxg3lbf.execute-api.us-east-2.amazonaws.com/demo/',
    },
    {
      type: 'ajustes',
      url: 'https://ubjm7bcvz7.execute-api.us-east-2.amazonaws.com/demo/',
    },
    {
      type: 'evaluacion',
      url: 'https://eb86kohaoj.execute-api.us-east-2.amazonaws.com/demo/',
    },
    {
      type: 'perfil',
      url: 'https://ubjm7bcvz7.execute-api.us-east-2.amazonaws.com/demo/',
    },
    {
      type: 'experto',
      url: 'https://fg5jm0svjk.execute-api.us-east-2.amazonaws.com/demo/',
    },
    { type: 'estadisticas', url: 'https://estadisticas.skalo.co/' },
  ];

  public EndPointsProd = [
    {
      type: 'datos_basicos',
      url: 'https://849qwc4fph.execute-api.us-east-2.amazonaws.com/prod/',
    },
    {
      type: 'registro_inst_est',
      url: 'https://4s9c7og3c3.execute-api.us-east-2.amazonaws.com/prod/',
    },
    {
      type: 'ajustes',
      url: 'https://bojddckzy4.execute-api.us-east-2.amazonaws.com/prod/',
    },
    {
      type: 'evaluacion',
      url: 'https://a6zbgnf1p7.execute-api.us-east-2.amazonaws.com/prod/',
    },
    {
      type: 'perfil',
      url: 'https://bojddckzy4.execute-api.us-east-2.amazonaws.com/prod/',
    },
    {
      type: 'experto',
      url: 'https://w21gxvvkrd.execute-api.us-east-2.amazonaws.com/prod/',
    },
    { type: 'estadisticas', url: 'https://estadisticas.skalo.co/' },
  ];

  public EndPointsUnit = [
    {
      type: 'datos_basicos',
      url: 'https://u8hslx03i6.execute-api.us-east-2.amazonaws.com/unit/',
    },
    {
      type: 'registro_inst_est',
      url: 'https://ee9rnhpky9.execute-api.us-east-2.amazonaws.com/unit/',
    },
    {
      type: 'ajustes',
      url: 'https://s150fcg165.execute-api.us-east-2.amazonaws.com/unit/',
    },
    {
      type: 'evaluacion',
      url: 'https://qtr2rzqsb5.execute-api.us-east-2.amazonaws.com/unit/',
    },
    {
      type: 'perfil',
      url: 'https://s150fcg165.execute-api.us-east-2.amazonaws.com/unit/',
    },
    {
      type: 'experto',
      url: 'https://w8vynnf8ae.execute-api.us-east-2.amazonaws.com/unit/',
    },
    { type: 'estadisticas', url: 'https://estadisticas.skalo.co/' },
  ];

  constructor(
    private cache: CacheService,
    private http: HttpClient
  ) {}

  public modoDebug() {
    return environment.debug;
  }

  /**
   * Genera la url con datos para consultas tipo get
   * @method makeDataURL
   * @command  {string} comando a invocar
   * @data {string} datos adicionales en get
   * @return {Promise}
   */
  public makeDataURL(type: string, command: string, data: string): string {
    // Build a weather service URL using the command string and
    // location data that we have.
    var endpoint;
    if (environment.production)
      // || environment.stage == 'prod'
      endpoint = this.EndPointsProd.filter(item => item.type === type);
    else if (environment.demo)
      endpoint = this.EndPointsDemo.filter(item => item.type === type);
    else if (environment.stage == 'unit')
      endpoint = this.EndPointsUnit.filter(item => item.type === type);
    else endpoint = this.EndPointsDev.filter(item => item.type === type);
    let uri = endpoint[0].url + command;
    // let uri = this.Endpoint + command ;
    // Configure output for imperial (English) measurements
    uri += data;
    // Return the value
    return uri;
  }

  /**
   * Extrae los datos de respuesta en formato json
   * @method extractData
   * @res  {Response} respuesta de la ejecucion get
   * @return {JSON}
   */
  // 'Borrowed' from //https://angular.io/docs/ts/latest/guide/server-communication.html
  public extractData(res: any) {
    // Convert the response to JSON format
    const body = res; // .json();
    // Return the data (or nothing)
    return body || {};
  }

  public handleError(res: Response | any) {
    console.error('Entering handleError');
    console.error(res);
    return Promise.reject(res.error.error || res.message || res);
  }

  public getCachedData(url: string) {
    const cacheKey = url;
    const request = this.http.get(url);
    //    this.cache.removeItem(cacheKey);
    return this.cache
      .loadFromObservable(cacheKey, request)
      .toPromise()
      .then(this.extractData)
      .catch(this.handleError);
  }
}
