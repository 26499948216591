import secrets from '../../secrets.json';

export const environment = {
  production: true,
  demo: false,
  debug: false,
  app: 'skalo-doc',
  hotjar: '3429504', // prod key
  stage: 'prod',
  cognitoTokenURL:
    'https://skalo-prod.auth.us-east-2.amazoncognito.com/oauth2/token',
  loginURL:
    'https://skalo-prod.auth.us-east-2.amazoncognito.com/login?client_id=2tq7nv5opamvb014gel3ckh7ov&response_type=code&scope=aws.cognito.signin.user.admin+email+openid+phone+profile&redirect_uri=https://app.doc.skalo.co/callback',
  logout:
    'https://skalo-prod.auth.us-east-2.amazoncognito.com/logout?response_type=code&client_id=2tq7nv5opamvb014gel3ckh7ov&redirect_uri=https://app.doc.skalo.co/callback',
  redirectUrl: 'https://app.doc.skalo.co/callback',
  registroUrl: 'https://usuario.skalo.co/registro',

  //loginURL: 'https://skalo-prod.auth.us-east-2.amazoncognito.com/login?client_id=2tq7nv5opamvb014gel3ckh7ov&response_type=code&scope=aws.cognito.signin.user.admin+email+openid+phone+profile&redirect_uri=http://localhost:8100/callback',
  //logout: 'https://skalo-prod.auth.us-east-2.amazoncognito.com/logout?response_type=code&client_id=2tq7nv5opamvb014gel3ckh7ov&redirect_uri=http://localhost:8100/callback',
  //redirectUrl: 'http://localhost:8100/callback',

  S3linkServidor: 'https://skalo-priv-prod.s3.us-east-2.amazonaws.com',
  S3accessKey: secrets.prod.S3AccessKeyDev,
  S3accessSecret: secrets.prod.S3AccessSecretDev,
  S3region: 'us-east-2',
  S3bucket: 'skalo-priv-prod',
  sso_api_username: secrets.prod.SsoApiUsername,
  sso_api_pwd: secrets.prod.SsoApiPassword,
  statsUrl:
    'https://skalo-dev.auth.us-east-2.amazoncognito.com/login?client_id=12253gb5cciahtkfds7tnnn1n6&response_type=code&scope=aws.cognito.signin.user.admin+email+openid+phone+profile&redirect_uri=https://estadisticas.skalo.co/login',
  statsUrlDocente:
    'https://skalo-prod.auth.us-east-2.amazoncognito.com/login?client_id=2tq7nv5opamvb014gel3ckh7ov&response_type=code&scope=aws.cognito.signin.user.admin+email+openid+phone+profile&redirect_uri=https://estadisticas.skalo.co/login',
};
